import { Link } from "react-router-dom";
import { useContext } from "react";
import IsAuthenticatedContext from "../../context/isauthenticated.context";

export default function Menu() {
  const [, setIsAuthenticated] = useContext(IsAuthenticatedContext);

  const logOut = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  return (
    <>
      <div style={{ position: "fixed", top: "5px", left: "5px", zIndex: 100 }}>
        <div>
          <Link to="/upload">Upload</Link>
        </div>
        <div>
          <Link to="/images">Images</Link>
        </div>
      </div>
      <div style={{ position: "fixed", top: "5px", right: "5px", zIndex: 100 }}>
        <button onClick={() => logOut()}>Logout</button>
      </div>
    </>
  );
}
