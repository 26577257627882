import "./style.scss";
import { AlertColor } from "@mui/material/Alert";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import Alert from "components/utils/alert";
import AlertContext from "context/alert.context";
import axios from "axios";
import IsAuthenticatedContext from "context/isauthenticated.context";
import Loader from "components/utils/loader";
import LoaderContext from "context/loader.context";
import Menu from "components/utils/menu";
import Router from "Router";
import socket from "socket/socket";

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertColor>("success");

  useEffect(() => {
    if (localStorage.getItem("token"))
      axios
        .get("/api/auth/verify", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(() => setIsAuthenticated(true))
        .catch(() => setIsAuthenticated(false));
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <AlertContext.Provider
      value={[message, setMessage, severity, setSeverity, open, setOpen]}
    >
      <LoaderContext.Provider value={[loading, setLoading]}>
        <IsAuthenticatedContext.Provider
          value={[isAuthenticated, setIsAuthenticated]}
        >
          <BrowserRouter>
            {isAuthenticated && <Menu />}
            <Router />
          </BrowserRouter>
          <Alert
            message={message}
            severity={severity}
            open={open}
            setOpen={setOpen}
          />
          <Loader loading={loading} />
        </IsAuthenticatedContext.Provider>
      </LoaderContext.Provider>
    </AlertContext.Provider>
  );
}
