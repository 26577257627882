import { Link } from "react-router-dom";

function Title() {
  return (
    <div className="title">
      <Link to="/polygone">midoudream</Link>
    </div>
  );
}

export default Title;
