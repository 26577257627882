import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

ReactDOMClient.createRoot(document.getElementById("root")!).render(<App />);

reportWebVitals();
