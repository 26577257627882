import { Colors } from "../enums/colors.enum";
import { Shade } from "types/shade.type";
import { useEffect, useState } from "react";
import axios from "axios";
import type { Image } from "../types/image.type";

function ImageList() {
  const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    axios.get("/api/images/all").then((res) => setImages(res.data));
  }, []);

  const toString = (shades: Shade[]): string => {
    return (
      Colors[shades[0].id] +
      " : " +
      shades[0].proportion +
      "\n" +
      Colors[shades[1].id] +
      " : " +
      shades[1].proportion
    );
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Image</th>
          <th>Width</th>
          <th>Height</th>
          <th>Color</th>
        </tr>
      </thead>
      <tbody>
        {images.map((e: Image) => (
          <tr key={e.id}>
            <td>{e.name}</td>
            <td>
              <img src={e.url} height="100" />
            </td>
            <td>{e.width}</td>
            <td>{e.height}</td>
            <td>{toString(e.shades)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ImageList;
