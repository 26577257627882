import { LoaderProps } from "props/loader.props";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader(props: LoaderProps) {
  return (
    <>
      {props.loading && (
        <div className="loader-cont">
          <Box sx={{ display: "flex" }}>
            <CircularProgress color="info" />
          </Box>
        </div>
      )}
    </>
  );
}
